import { Box } from "@mui/material";
import { Viewer } from "../components";
import { memo } from "react";

const Home = memo(function Home() {
  return (
    <Box height="100vh" bgcolor="common.white">
      <Viewer />
    </Box>
  );
});

export { Home };
