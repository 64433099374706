import _ from 'lodash';
import { Material } from './material';
import { ObjMesh } from './objMesh';
import { MATERIALS } from './enums';

function Item(props) {
  const { modelId, materialId } = props,
    material = _.chain(MATERIALS).map('items').flatten(1).find({ id: materialId }).value();

  return (
    <group>
      <ObjMesh resource={`${modelId}/main`} castShadow receiveShadow>
        <Material resource={materialId} {...material.opts} flatShading />
      </ObjMesh>
      <ObjMesh resource={`${modelId}/seals`}>
        <meshStandardMaterial color={0x0d0d0d} />
      </ObjMesh>
      <ObjMesh resource={`${modelId}/glass`}>
        <meshPhysicalMaterial color={0xffffff} ior={1.89} transmission={1} roughness={0} opacity={0.5} transparent />
      </ObjMesh>
    </group>
  );
}

export { Item };
