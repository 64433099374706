import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useResourceLoader } from './components/viewer/loader';
import * as yup from 'yup';
import { OBJLoader } from 'three/examples/jsm/Addons.js';
import { TextureLoader } from 'three';
import { getResourceUrl } from './utils';
import * as THREE from 'three';

function useMemoizedVector3(point) {
  yup
    .mixed()
    .required()
    .test('is-vector3-instance', 'Value must be an instance of THREE.Vector3', (value) => value.isVector3);
  const [memoPoint, setMemoPoint] = useState(point);
  useEffect(() => {
    if (!point.equals(memoPoint)) {
      setMemoPoint(point);
    }
  }, [point, memoPoint, setMemoPoint]);

  return point;
}

function useObjMesh(resource, opts = {}) {
  yup.string().required().validateSync(resource);
  yup
    .object({
      local: yup.boolean().default(false)
    })
    .optional()
    .validateSync(opts);
  const { local } = opts,
    urlParser = local ? _.identity : getResourceUrl,
    objGroup = useResourceLoader(OBJLoader, urlParser(`models/${resource}/model.obj`), undefined, [resource, local]),
    aoMap = useResourceLoader(TextureLoader, urlParser(`models/${resource}/ao.jpg`), { fallbackOnError: true }, [resource, local]),
    alphaMap = useResourceLoader(TextureLoader, urlParser(`models/${resource}/alpha.jpg`), { fallbackOnError: true }, [resource, local]);

  useEffect(() => {
    if (!_.isNil(aoMap)) {
      aoMap.colorSpace = THREE.NoColorSpace;
      aoMap.needsUpdate = true;
    }
  }, [aoMap]);

  useEffect(() => {
    if (!_.isNil(alphaMap)) {
      alphaMap.colorSpace = THREE.NoColorSpace;
      alphaMap.needsUpdate = true;
    }
  }, [alphaMap]);

  return [objGroup, aoMap, alphaMap];
}

function useSafeRef(ref) {
  const fallbackRef = useRef();
  return ref ?? fallbackRef;
}

export { useMemoizedVector3, useObjMesh, useSafeRef };
