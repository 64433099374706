import { Canvas, useThree } from '@react-three/fiber';
import { Suspender } from './loader';
import { memo, useEffect, useState } from 'react';
import { ShowRoom } from './showRoom';
import _ from 'lodash';
import { MATERIALS, MODELS } from './enums';
import { SceneControls } from './sceneControls';
import { CameraControls as DreiCameraControls } from '@react-three/drei';
import * as THREE from 'three';

const Viewer = memo(function Viewer() {
  const [glOptions] = useState({
      physicallyCorrectLights: true,
      powerPreference: 'high-performance',
      antialias: true,
      toneMapping: THREE.ACESFilmicToneMapping
      // stencil: false,
      // depth: false,
    }),
    [cameraOptions] = useState({ near: 1e-2, position: [1, 1.5, 2] }),
    [sceneConfig, setSceneConfig] = useState({
      model: _.first(MODELS).id,
      materialType: _.first(MATERIALS).id,
      material: _.chain(MATERIALS).first().get('items').first().get('id').value()
    });

  return (
    <>
      <SceneControls value={sceneConfig} onChange={setSceneConfig} />
      <Suspender>
        <Canvas gl={glOptions} performance={{ min: 0.1, debounce: 200 }} camera={cameraOptions} frameloop="demand" shadows="variance">
          <CameraControls target={new THREE.Vector3(0, 1, 0)} maxPolarAngle={Math.PI} makeDefault />
          <ShowRoom modelId={sceneConfig.model} materialId={sceneConfig.material} />
        </Canvas>
      </Suspender>
    </>
  );
});

function CameraControls(props) {
  const { controls } = useThree(),
    { target, ...passProps } = props;

  useEffect(() => {
    if (!_.isNil(controls) && !_.isNil(target)) {
      const currentTarget = controls.getTarget(new THREE.Vector3());
      if (!currentTarget.equals(target)) {
        controls.setTarget(...target.toArray());
      }
    }
  }, [controls, target]);

  return <DreiCameraControls {...passProps} />;
}

export { Viewer };
