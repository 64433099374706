import { Children, cloneElement, createContext, forwardRef, isValidElement, memo, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import * as yup from 'yup';
import { useObjMesh } from '../../hooks';

const ObjMesh = forwardRef(function ObjMesh(props, ref) {
  yup.string().required().validateSync(props.resource);
  const { resource, local, castShadow, receiveShadow, ...passProps } = props,
    [objGroup, aoMap, alphaMap] = useObjMesh(resource, { local: Boolean(local) }),
    [geometries, setGeometries] = useState([]),
    materialOverrides = useMemo(() => _.omitBy({ aoMap, alphaMap }, _.isNil), [aoMap, alphaMap]);

  useEffect(() => {
    if (!_.isNil(objGroup)) {
      const geometries = [];
      objGroup.traverse((c) => {
        if (c.isMesh) {
          geometries.push(c.geometry);
        }
      });
      setGeometries(geometries);
    }
  }, [objGroup, aoMap, alphaMap, setGeometries]);

  return (
    <group {...passProps}>
      {_.map(geometries, (geometry, index) => {
        return (
          <mesh key={index} geometry={geometry} castShadow={castShadow} receiveShadow={receiveShadow}>
            {Children.map(props.children ?? [], (child, index) => {
              return isValidElement(child) ? cloneElement(child, { index: index, ...materialOverrides }) : child;
            })}
          </mesh>
        );
      })}
    </group>
  );
});

export { ObjMesh };
