import _ from 'lodash';
import { useMemo } from 'react';
import * as THREE from 'three';
import { Base, Geometry, Subtraction } from '@react-three/csg';
import { MODELS, SCENE_MATERIAL, SCENE_MATERIAL_COLOR, WALL_THICKNESS } from './enums';
import { Item } from './item';
import { LightSetup } from './lightSetup';
import { ContactShadows } from '@react-three/drei';
import { ObjMesh } from './objMesh';

function ShowRoom(props) {
  const { modelId, materialId } = props,
    model = _.find(MODELS, { id: modelId }),
    cutout = useMemo(() => {
      const lower = new THREE.Vector3(...model.cutout.lower),
        upper = new THREE.Vector3(...model.cutout.upper),
        center = new THREE.Vector3().lerpVectors(lower, upper, 0.5),
        size = new THREE.Vector3().subVectors(upper, lower);

      return {
        lower,
        upper,
        center,
        size
      };
    }, [model.cutout]),
    wall = useMemo(() => {
      const lower = cutout.lower
          .clone()
          .setX(cutout.lower.x / 2 - 2)
          .setY(0),
        upper = cutout.upper
          .clone()
          .setX(cutout.upper.x / 2 + 2)
          .setY(cutout.upper.y + 0.5),
        center = new THREE.Vector3().lerpVectors(lower, upper, 0.5),
        size = new THREE.Vector3().subVectors(upper, lower);

      return {
        lower,
        upper,
        center,
        size
      };
    }, [cutout]);

  return (
    <group>
      <LightSetup />
      <Item modelId={model.id} materialId={materialId} />
      <mesh material={SCENE_MATERIAL} castShadow receiveShadow>
        <Geometry>
          <Base position={[...wall.center.toArray()]}>
            <boxGeometry args={[...wall.size.toArray()]} />
          </Base>
          <Subtraction position={[...cutout.center.toArray()]}>
            <boxGeometry args={[...cutout.size.toArray()]} />
          </Subtraction>
        </Geometry>
      </mesh>
      <group position={[wall.lower.x + 0.5, 0, -(WALL_THICKNESS / 2 + 0.5)]}>
        <ObjMesh resource="plant01/solid" local castShadow receiveShadow>
          <primitive object={SCENE_MATERIAL} />
        </ObjMesh>
        <ObjMesh resource="plant01/alpha" local castShadow receiveShadow depthTest={false}>
          <meshStandardMaterial color={SCENE_MATERIAL_COLOR} transparent side={THREE.DoubleSide} />
        </ObjMesh>
      </group>
      <group position={[-(wall.upper.x + 1), 0, WALL_THICKNESS / 2 + 2]} scale={[0.75, 0.75, 0.75]}>
        <ObjMesh resource="plant02/solid" local castShadow receiveShadow>
          <primitive object={SCENE_MATERIAL} />
        </ObjMesh>
        <ObjMesh resource="plant02/alpha" local castShadow receiveShadow>
          <meshStandardMaterial color={SCENE_MATERIAL_COLOR} transparent side={THREE.DoubleSide} />
        </ObjMesh>
      </group>
      <group position={[wall.lower.x, 0, 2.5 + WALL_THICKNESS / 2]} rotation={[0, Math.PI / 2, 0]}>
        <ObjMesh resource="railing" local castShadow receiveShadow>
          <primitive object={SCENE_MATERIAL} />
        </ObjMesh>
      </group>
      <group position={[wall.upper.x - 0.5, 0, -2]} rotation={[0, -Math.PI / 2, 0]}>
        <ObjMesh resource="sofa" local castShadow receiveShadow>
          <primitive object={SCENE_MATERIAL} />
        </ObjMesh>
      </group>
      <ContactShadows far={0.125} blur={0.125} opacity={0.5} />
    </group>
  );
}

function Plant(props) {}

export { ShowRoom };
