import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useResourceLoader } from "./loader";
import {
  NoColorSpace,
  RepeatWrapping,
  SRGBColorSpace,
  TextureLoader,
} from "three";
import { getResourceUrl } from "../../utils";

function postProcessTexture(texture, opts = {}) {
  const { repeat = 1 } = opts;
  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.repeat.set(repeat, repeat);
  texture.needsUpdate = true;
  return texture.clone();
}

function Material(props) {
  yup.string().required().validateSync(props.resource);
  const { resource, repeat, fileExt = "jpg", local, ...passProps } = props,
    urlParser = local ? _.identity : getResourceUrl,
    [loading, setLoading] = useState(true),
    [alphaRoughMetMap, map, normalMap] = useResourceLoader(
      TextureLoader,
      [
        urlParser(`materials/${resource}/alphaRoughMet.${fileExt}`),
        urlParser(`materials/${resource}/map.${fileExt}`),
        urlParser(`materials/${resource}/normal.${fileExt}`),
      ],
      {
        fallbackOnError: true,
        onLoad: (results) => {
          setLoading(false);
          return _.chain(results)
            .map((texture) => {
              return _.isNil(texture)
                ? undefined
                : postProcessTexture(texture, { repeat: repeat });
            })
            .value();
        },
      },
      [resource, local, repeat]
    ),
    materialArgs = _.chain({
      aoMap: alphaRoughMetMap,
      map,
      metalnessMap: alphaRoughMetMap,
      normalMap,
      roughnessMap: alphaRoughMetMap,
    })
      .omitBy(_.isNil)
      .assign(passProps)
      .value();

  useEffect(() => {
    setLoading(true);
  }, [resource]);

  useEffect(() => {}, [loading]);

  //Workaround because somewhere threejs is overriding texture colorSpace upon loading
  useEffect(() => {
    _.chain([alphaRoughMetMap, normalMap])
      .filter((texture) => !_.isNil(texture))
      .each((texture) => {
        texture.colorSpace = NoColorSpace;
        texture.needsUpdate = true;
      })
      .value();
  }, [alphaRoughMetMap, normalMap]);

  useEffect(() => {
    if (!_.isNil(map)) {
      map.colorSpace = SRGBColorSpace;
      map.needsUpdate = true;
    }
  }, [map]);

  if (loading) {
    return null;
  }

  return <meshPhysicalMaterial {...materialArgs} />;
}

export { Material };
