import _ from 'lodash';
import { Autocomplete, Box, Card, CardContent, FormControl, Stack, TextField } from '@mui/material';
import { memo, useCallback } from 'react';
import { MATERIALS, MODELS } from './enums';

const SceneControls = memo(function SceneControls(props) {
  const { value } = props,
    { materialType, material, model } = value,
    allowedMaterials = _.find(MATERIALS, { id: materialType }).items,
    onChange = useCallback(
      (value) => {
        if (_.isFunction(props.onChange)) {
          props.onChange(value);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.onChange]
    ),
    onMaterialTypeChange = useCallback((e, matType) => {
      const allowedMaterials = _.find(MATERIALS, { id: matType.id }).items,
        newValue = _.chain(value ?? {})
          .clone()
          .extend({ materialType: matType.id })
          .value();

      if (!_.chain(allowedMaterials).map('id').includes(newValue.material).value()) {
        _.extend(newValue, { material: _.first(allowedMaterials).id });
      }

      onChange(newValue);
    }),
    onMaterialChange = useCallback(
      (e, mat) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ material: mat.id })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    onModelChange = useCallback(
      (e, model) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ model: model.id })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    );

  return (
    <Box position="fixed" m={2} zIndex={101}>
      <Card style={{ overflow: 'visible', minWidth: '256px' }}>
        <CardContent>
          <Stack gap={2}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                disableClearable
                options={MODELS}
                value={_.find(MODELS, { id: model })}
                onChange={onModelChange}
                renderInput={(params) => <TextField {...params} label="Modello" />}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                disableClearable
                options={MATERIALS}
                value={_.find(MATERIALS, { id: materialType })}
                onChange={onMaterialTypeChange}
                renderInput={(params) => <TextField {...params} label="Tipo finitura" />}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                disableClearable
                options={allowedMaterials}
                value={_.find(allowedMaterials, { id: material })}
                onChange={onMaterialChange}
                renderInput={(params) => <TextField {...params} label="Finitura" />}
              />
            </FormControl>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
});

export { SceneControls };
