import * as THREE from 'three';
import { metalness } from 'three/examples/jsm/nodes/Nodes.js';

const WALL_THICKNESS = 0.2,
  SCENE_MATERIAL_COLOR = 0xefefef,
  MODELS = [
    {
      id: 'sl1600tt',
      label: 'LS1600TT',
      cutout: {
        lower: [-1.5, 0, -WALL_THICKNESS / 2],
        upper: [1.5, 2.2, WALL_THICKNESS / 2]
      }
    },
    {
      id: 'ws75thi',
      label: 'WS75THI',
      cutout: {
        lower: [-0.565, 0, -WALL_THICKNESS / 2],
        upper: [0.565, 2.165, WALL_THICKNESS / 2]
      }
    }
  ],
  MATERIALS = [
    {
      id: 'brushed',
      label: 'Spazzolato',
      items: [
        {
          id: 'metal000',
          label: 'Metallo 000',
          opts: {
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal001',
          label: 'Metallo 001',
          opts: {
            color: 0x41241f,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal002',
          label: 'Metallo 002',
          opts: {
            color: 0x265997,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal003',
          label: 'Metallo 003',
          opts: {
            color: 0x793a19,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal004',
          label: 'Metallo 004',
          opts: {
            color: 0x7c691f,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal005',
          label: 'Metallo 005',
          opts: {
            color: 0x615c3c,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal006',
          label: 'Metallo 006',
          opts: {
            color: 0x7c522f,
            metalness: 1,
            repeat: 50
          }
        },
        {
          id: 'metal007',
          label: 'Metallo 007',
          opts: {
            color: 0xf1c102,
            metalness: 1,
            repeat: 50
          }
        }
      ]
    },
    {
      id: 'ral',
      label: 'Verniciato RAL',
      items: [
        {
          id: '9010',
          label: 'RAL 9010',
          opts: {
            color: 0xedefe4,
            roughness: 0.125
          }
        },
        {
          id: 'K9010',
          label: 'RAL K9010',
          opts: {
            color: 0xedefe4,
            roughness: 0.75
          }
        },
        {
          id: '9001',
          label: 'RAL 9001',
          opts: {
            color: 0xfdf4e3,
            roughness: 0.125
          }
        },
        {
          id: 'K9001',
          label: 'RAL K9001',
          opts: {
            color: 0xfdf4e3,
            roughness: 0.75
          }
        },
        {
          id: '7016',
          label: 'RAL 7016',
          opts: {
            color: 0x293133,
            roughness: 0.125
          }
        },
        {
          id: 'K7016',
          label: 'RAL K7016',
          opts: {
            color: 0x293133,
            roughness: 0.75
          }
        },
        {
          id: '7035',
          label: 'RAL 7035',
          opts: {
            color: 0xcbd0cc,
            roughness: 0.125
          }
        },
        {
          id: 'K7035',
          label: 'RAL K7035',
          opts: {
            color: 0xcbd0cc,
            roughness: 0.75
          }
        },
        {
          id: '7015',
          label: 'RAL 7015',
          opts: {
            color: 0x434750,
            roughness: 0.125
          }
        },
        {
          id: 'K7015',
          label: 'RAL K7015',
          opts: {
            color: 0x434750,
            roughness: 0.75
          }
        },
        {
          id: '7032',
          label: 'RAL 7032',
          opts: {
            color: 0xb8b799,
            roughness: 0.125
          }
        },
        {
          id: 'K7032',
          label: 'RAL K7032',
          opts: {
            color: 0xb8b799,
            roughness: 0.75
          }
        },
        {
          id: '7031',
          label: 'RAL 7031',
          opts: {
            color: 0x474b4e,
            roughness: 0.125
          }
        },
        {
          id: 'K7031',
          label: 'RAL K7031',
          opts: {
            color: 0x474b4e,
            roughness: 0.75
          }
        },
        {
          id: '7012',
          label: 'RAL 7012',
          opts: {
            color: 0x4e5754,
            roughness: 0.125
          }
        },
        {
          id: 'K7012',
          label: 'RAL K7012',
          opts: {
            color: 0x4e5754,
            roughness: 0.75
          }
        },
        {
          id: '9005',
          label: 'RAL 9005',
          opts: {
            color: 0x0a0a0a,
            roughness: 0.125
          }
        },
        {
          id: 'K9005',
          label: 'RAL K9005',
          opts: {
            color: 0x0a0a0a,
            roughness: 0.75
          }
        },
        {
          id: '7030',
          label: 'RAL 7030',
          opts: {
            color: 0x8b8c7a,
            roughness: 0.125
          }
        },
        {
          id: 'K7030',
          label: 'RAL K7030',
          opts: {
            color: 0x8b8c7a,
            roughness: 0.75
          }
        },
        {
          id: '3003',
          label: 'RAL 3003',
          opts: {
            color: 0x8b0019,
            roughness: 0.125
          }
        },
        {
          id: 'K3003',
          label: 'RAL K3003',
          opts: {
            color: 0x8b0019,
            roughness: 0.75
          }
        }
      ]
    },
    {
      id: 'wood',
      label: 'Effetto legno',
      items: [
        {
          id: 'wood026',
          label: 'Legno 026',
          opts: {
            repeat: 25,
            metalness: 1
          }
        },
        {
          id: 'wood027',
          label: 'Legno 027',
          opts: {
            repeat: 25,
            metalness: 1
          }
        },
        {
          id: 'wood049',
          label: 'Legno 049',
          opts: {
            repeat: 25,
            metalness: 1
          }
        },
        {
          id: 'wood051',
          label: 'Legno 051',
          opts: {
            repeat: 40,
            metalness: 1
          }
        },
        {
          id: 'wood066',
          label: 'Legno 066',
          opts: {
            repeat: 25,
            metalness: 1
          }
        },
        {
          id: 'wood067',
          label: 'Legno 067',
          opts: {
            repeat: 25,
            metalness: 1
          }
        }
      ]
    }
  ],
  SCENE_MATERIAL = new THREE.MeshStandardMaterial({
    color: SCENE_MATERIAL_COLOR
  });

export { MATERIALS, MODELS, SCENE_MATERIAL, SCENE_MATERIAL_COLOR, WALL_THICKNESS };
