import _ from 'lodash';
import { memo } from 'react';
import { Environment } from '@react-three/drei';

const LIGHTS_N = 4,
  LIGHTS_POS = [],
  LIGHT_ANGLE_OFFSET = Math.PI / 4;
for (let i = 0; i < LIGHTS_N; i++) {
  const angle = ((2 * Math.PI) / LIGHTS_N) * i + LIGHT_ANGLE_OFFSET;
  LIGHTS_POS.push([1.5 * Math.sin(angle), 4, 1.5 * Math.cos(angle)]);
}

const LightSetup = memo(function LightSetup() {
  const baseIntensity = 10;

  return (
    <group>
      {_.map(LIGHTS_POS, (pos, index) => {
        return (
          <directionalLight
            key={index}
            color={0xfff9fb}
            intensity={(2 * baseIntensity) / LIGHTS_POS.length}
            position={pos}
            castShadow
            shadow-bias={-1e-3}
            shadow-blurSamples={8}
            shadow-radius={2}
          />
        );
      })}
      <Environment
        files={[
          `env/showroom01/px.jpg`,
          `env/showroom01/nx.jpg`,
          `env/showroom01/py.jpg`,
          `env/showroom01/ny.jpg`,
          `env/showroom01/pz.jpg`,
          `env/showroom01/nz.jpg`
        ]}
        environmentIntensity={0.25 * baseIntensity}
        environmentRotation={[0, Math.PI, 0]}
      />
    </group>
  );
});

export { LightSetup };
