import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import ResourceLoader from "../resourceLoader";
import { useLoadingStateUpdater, useRegisterLoader } from "./suspender";

const DefaultOptions = {
  onLoad: _.identity,
  onError: _.identity,
};

function useResourceLoader(loader, resource, options = {}, deps = []) {
  const uuidRef = useRef(_.uniqueId()),
    uuid = uuidRef.current,
    [memoDeps, setMemoDeps] = useState(),
    [asset, setAsset] = useState(
      _.isArray(resource) ? _.map(resource, (v) => undefined) : undefined
    ),
    loadingStateUpdater = useLoadingStateUpdater(uuid);

  useRegisterLoader(uuid);

  useEffect(() => {
    if (!_.isEqual(deps, memoDeps)) {
      setMemoDeps(deps);
      const { extend, onLoad, onError, fallbackOnError } = _.defaults(
          options,
          DefaultOptions
        ),
        loadPromise = _.isArray(resource)
          ? Promise.allSettled(
              resource.map((r) => ResourceLoader.load(loader, r, extend))
            ).then((results) => {
              const containsErrors = _.some(
                results,
                (r) => r.status === "rejected"
              );
              if (!containsErrors) {
                return _.map(results, (r) => r.value);
              } else if (!Boolean(fallbackOnError)) {
                throw new Error("Failed to load resources");
              } else {
                return _.map(results, (r) =>
                  r.status === "fulfilled" ? r.value : undefined
                );
              }
            })
          : ResourceLoader.load(loader, resource, extend);

      loadingStateUpdater(true);

      loadPromise
        .then(onLoad)
        .then(setAsset)
        .then(() => {
          loadingStateUpdater(false);
        })
        .catch((e) => {
          onError(e);
          const message = _.isArray(resource)
            ? `[useResourceLoader] Failed to load resources: ${resource.join(
                ", "
              )}`
            : `[useResourceLoader] Failed to load resource: ${resource}`;
          if (!Boolean(fallbackOnError)) {
            throw new Error(message);
          } else {
            console.warn(message);
            loadingStateUpdater(false);
          }
        });
    }
  }, [
    loader,
    resource,
    options,
    deps,
    memoDeps,
    setMemoDeps,
    setAsset,
    loadingStateUpdater,
  ]);

  return asset;
}

export { useResourceLoader };
